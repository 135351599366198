<script>
import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";

import { required, } from "vuelidate/lib/validators";
import Choices from "choices.js";

import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";

import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";

import VueBootstrapTypeahead from 'vue-bootstrap-typeahead'


import { useSystemApi } from "@/apis/system";
import { useStockApi } from "@/apis/stock";


/**
 * Advanced table component
 */
export default {
  page: {
    title: '库存管理-出库',
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    flatPickr,
    vueDropzone: vue2Dropzone,
    VueBootstrapTypeahead,

  },

  validations() {
    return {
      p_model: { required },
      p_stock_number: { required },
    }
  },
  data() {
    return {
      title: '库间流转',
      items: [
        {
          text: this.$t('stock.list.nav_first_item'),
          href: "/",
        },
        {
          text: '库间流转',
          active: true,
        },
      ],

      has_data_error: false,
      data_err_msg: '',

      listingData: [],
      model_data: [],
      team_data: [],
      storageList : [],

      p_list_fields: [
        { key: 'Code', label: '型号' },
        { key: 'PNumber', label: '批号' },
        { key: 'PCount', label: '数量' },
        { key: 'Action', label: '操作' },
      ],


      stock_date: '',
      p_model: '',
      p_stock_number: '',
      p_number: '',
      p_responser: '',
      stock_remarks : '',

      inStorage  : '',
      outStorage : '',



      dropzoneOptions: {
        url: "/api/v1/task/upload_produces",
        thumbnailWidth: 150,
        maxFilesize: 1,
      },

    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return 0
    },


  },
  mounted() {


    // useSystemApi().list_system_conf('DEPT').then(res => {
    //   console.log(res)
    //   if (res.err == 0) {
    //     res.data.map(e => {
    //       this.team_data.push({ value: e.key_field, label: e.value_field })
    //     })

    //     new Choices('#team_choice', { choices: this.team_data })
    //     this.p_team = this.team_data[0].value
    //   }

    // })



    this.query_storage()



  },
  methods: {

    formSubmit() {

      let data = {
       
        storage_out : this.outStorage,
        storage_in  : this.inStorage,
        action   : 'TRANSFER',
        responser: '李红丽',
        produces: []
      }

      this.listingData.map(e => {
        data.produces.push({
          model   : e.code,
          number  : e.pcount,
          p_number: e.pnumber,
        })
      })


      useStockApi().put_in_stock(data).then(res => {
        if (res.err == 0) {

          this.$alertify.message("流转成功");
          this.$router.push({ name: 'stock-list' })
        } else {
          this.$alertify.error("流转失败 " + res.err + ":" + res.msg);
        }
      })
    },

    onAddPlist() {
      this.$v.$touch()

      if (this.$v.$invalid == false) {

        let list_model = this.listingData.find(e => e.code == this.p_model)
        if (list_model) {
          list_model.pcount += Number(this.p_stock_number)
        } else {
          this.listingData.push({
            code: this.p_model,
            pnumber: this.p_number,
            leader: this.p_responser,
            pcount: Number(this.p_stock_number),
          })
        }

        this.p_produce_total_number = this.listingData.reduce((a, c) => a += Number(c.pcount), 0)
      }

    },


    removeProduce(meta) {
      this.listingData.splice(meta.index, 1)
    },


    fileUploaded(evt, resp) {
      console.log(evt, resp)
    },


    query_storage() {
      useSystemApi().list_system_conf('STORAGE').then(res => {
        if (res.err == 0) {
         
          res.data.map(e => {
           
            this.storageList.push({
              code    : e.key_field,
              name    : e.value_field,
              remarks : e.remarks,
              id      : e.id,
              value   : e.key_field,
              label   : e.value_field
            })
          })
        }

        let last_storage = this.storageList[this.storageList.length -1]
        last_storage.selected = true
        new Choices('#storage_out_list', {choices : this.storageList})
        new Choices('#storage_in_list', {choices : this.storageList})

        this.inStorage = last_storage.value
        this.outStorage = last_storage.value
        
      })
    },

    search_model(searched_str) {
      useSystemApi().model_search('MODEL', searched_str).then(res => {
        console.log(res)
        if (res.err == 0) {
          this.model_data = []
          res.data.map(e => {
            this.model_data.push(e.key_field)
          })
        }
      
      })
    }
  },

  watch: {
    p_model: function(new_val) {
      this.search_model(new_val)
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-12">
        <form class="needs-validation" @submit.prevent="formSubmit">
          <div class="card">


            <div class="card border shadow-none mb-3">
              <div class="card-header d-flex align-items-center">
                <div class="flex-shrink-0 me-3">
                  <div class="avatar-sm">
                    <div class="avatar-title rounded-circle bg-soft-primary text-primary">
                      01
                    </div>
                  </div>
                </div>
                <div class="flex-grow-1">
                  <h5 class="card-title">库间流转基本信息</h5>
                </div>
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-md-2">
                    <label class="form-label">入库时间</label>
                    <div class="mb-3">
                      <flat-pickr v-model="stock_date" placeholder="Select a date" class="form-control"></flat-pickr>
                    </div>

                  </div>


                  <div class="col-md-2">
                    <label class="form-label">出库</label>
                    <select
                      class="form-control"
                      id="storage_out_list"
                      v-model ="outStorage"
                    ></select>

                  </div>


                  <div class="col-md-2">
                    <label class="form-label">入库</label>
                    <select
                      class="form-control"
                      id="storage_in_list"
                      v-model ="inStorage"
                    ></select>

                  </div>

                  <div class="col-md-2">
                    <label class="form-label">操作员</label>
                    <input class="form-control" v-model="p_responser" />

                  </div>


                  <div class="col-md-4">
                    <label class="form-label">说明</label>
                    <input class="form-control" v-model="stock_remarks" />

                  </div>


                </div>

              </div>
            </div>
            <!-- end section 1 -->


            <div class="card border shadow-none mb-3">
              <div class="card-header d-flex align-items-center">
                <div class="flex-shrink-0 me-3">
                  <div class="avatar-sm">
                    <div class="avatar-title rounded-circle bg-soft-primary text-primary">
                      02
                    </div>
                  </div>
                </div>
                <div class="flex-grow-1">
                  <h5 class="card-title">{{ $t('produce.new_task.section_2_title') }}</h5>
                </div>
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-md-8">
                    <div class="row">
                      <div class="col-md-3">
                        <label class="form-label">加工零件型号</label>
                        <div class="mb-3">
                          <vue-bootstrap-typeahead v-model="p_model" :data="model_data" autocomplete="off"
                            :inputClass="{ 'is-invalid': $v.p_model.$error }" />
                          <div v-if="$v.p_model.$error" class="invalid-feedback">
                            <span v-if="!$v.p_model.required">{{ $t('produce.new_task.err.required') }}</span>
                          </div>
                        </div>



                      </div>
                      <div class="col-md-2">
                        <label class="form-label">批号</label>
                        <input class="form-control" v-model="p_number" />


                      </div>


                      <div class="col-md-2">
                        <label class="form-label">数量</label>
                        <input class="form-control" v-model="p_stock_number"
                          :class="{ 'is-invalid': $v.p_stock_number.$error }" />
                        <div v-if="$v.p_stock_number.$error" class="invalid-feedback">
                          <span v-if="!$v.p_stock_number.required">{{ $t('produce.new_task.err.required') }}</span>
                        </div>

                      </div>

                    </div>
                    <div class="row mb-3">



                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <div class="d-flex flex-wrap gap-2 justify-content-end d-flex align-items-right">
                          <button type="button" class="btn btn-secondary w-sm" @click="onAddPlist"> {{
                            $t('produce.new_task.btn.add') }} </button>

                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <vue-dropzone id="dropzone" ref="myVueDropzone" :use-custom-slot="true" :options="dropzoneOptions"
                      @vdropzone-success="fileUploaded">
                      <div class="dropzone-custom-content">
                        <i class="display-4 text-muted bx bxs-cloud-upload"></i>
                        <h4>拖拽入库清单， 并上传.</h4>
                      </div>
                    </vue-dropzone>
                  </div>
                </div>

              </div>
            </div>
            <!-- end section 2 -->


            <div class="card border shadow-none mb-5">
              <div class="card-header d-flex align-items-center">
                <div class="flex-shrink-0 me-3">
                  <div class="avatar-sm">
                    <div class="avatar-title rounded-circle bg-soft-primary text-primary">
                      03
                    </div>
                  </div>
                </div>
                <div class="flex-grow-1">
                  <h5 class="card-title">流转清单</h5>
                </div>
              </div>
              <div class="card-body">
                <div data-simplebar>
                  <div class="table-responsive mt-3">
                    <div class="table align-middle table-nowrap">
                      <b-table :items="listingData" :fields="p_list_fields" responsive="sm"
                        :per-page="listingData.length" class="table-check">
                        <template #cell(Code)="data">
                          {{ data.item.code }}
                        </template>
                        <template #cell(PNumber)="data">
                          {{ data.item.pnumber }}
                        </template>

                        <template #cell(Team)="data">
                          {{ data.item.team }}
                        </template>
                        <template #cell(Leader)="data">
                          {{ data.item.leader }}
                        </template>
                        <template #cell(PCount)="data">
                          {{ data.item.pcount }}
                        </template>

                        <template #cell(Action)="data">
                          <b-dropdown variant="white" toggle-class="p-0">
                            <template v-slot:button-content>
                              <i class="mdi mdi-dots-horizontal font-size-18 text-muted"></i>
                            </template>
                            <li>
                              <b-dropdown-item @click="removeProduce(data)"><i
                                  class="mdi mdi-pencil font-size-16 text-success me-1"></i> 移除
                              </b-dropdown-item>
                            </li>
                          </b-dropdown>
                        </template>

                      </b-table>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-12">
                    <div class="d-flex flex-wrap gap-2 justify-content-end d-flex align-items-right">
                      <button type="button" class="btn btn-secondary w-sm"
                        @click="$router.push({ name: 'stock-list' })"> {{
                          $t('produce.new_task.btn.cancel') }} </button>
                      <button type="submit" class="btn btn-primary w-sm"> 确认库间流转</button>
                    </div>
                  </div>
                </div>

              </div>
            </div>
            <!-- end card -->





          </div>
        </form>
      </div>
    </div>

  </Layout>
</template>
